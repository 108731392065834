"use client";

import {
  ActionText,
  breakpointDesktop,
  IconButton,
  Icon,
} from "@moller/design-system";
import { styled } from "styled-components";
import { useEffect, useState } from "react";
import { Menu } from "./menu";

const MobileMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow: auto;
  top: 55px;
  right: 0;
  left: 0;
  z-index: 100;
  background-color: var(--moller-color-background);
  padding: var(--moller-spacing-xl) var(--moller-spacing-l);
  height: calc(
    100vh - 55px - calc(var(--moller-spacing-xl) * 2) - env(safe-area-inset-top) - env(
        safe-area-inset-bottom
      )
  );
  height: -webkit-fill-available;
  row-gap: var(--moller-spacing-xl);

  > a {
    color: var(--moller-color-on-background);
    font-size: var(--moller-typeface-size-l);
    text-decoration: none;
  }

  @media screen and (min-width: ${breakpointDesktop}) {
    display: none;
  }
`;

const ToggleMenuButton = styled(IconButton)`
  margin-left: auto;

  @media screen and (min-width: ${breakpointDesktop}) {
    display: none !important;
  }
`;

const MenuButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--moller-color-on-background);
  > div {
    font-size: var(--moller-typeface-size-l);
  }
`;

const Chevron = styled(Icon)`
  margin-left: var(--moller-spacing-xxs);
`;

export const MobileMenu = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isDepartmentsOpen, setDepartmentsOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  return (
    <>
      <ToggleMenuButton
        icon={isMenuOpen ? "close" : "menu"}
        label="Meny"
        onClick={() => setMenuOpen(!isMenuOpen)}
      />
      {isMenuOpen && (
        <MobileMenuWrapper>
          <ActionText $as="a" href="/">
            Alle biler
          </ActionText>
          <MenuButton onClick={() => setDepartmentsOpen(!isDepartmentsOpen)}>
            <ActionText>Velg forhandler</ActionText>
            <Chevron icon={isDepartmentsOpen ? "expand_less" : "expand_more"} />
          </MenuButton>
          {isDepartmentsOpen && (
            <Menu
              closeMenu={() => {
                setMenuOpen(false);
                setDepartmentsOpen(false);
              }}
            />
          )}
        </MobileMenuWrapper>
      )}
    </>
  );
};
