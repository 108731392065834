import { removeSpacingFromString } from "@/utils/removeSpacingFromString";

export const calculateTradeInPrice = (marketPrice: string) => {
  const marketPriceNumber = Number(removeSpacingFromString(marketPrice));
  const profit = Math.round((marketPriceNumber / 100) * 7.5);
  const salesCosts = 15000;
  const sumCosts = salesCosts + profit;

  if (marketPrice && marketPriceNumber > sumCosts) {
    return marketPriceNumber - sumCosts;
  }
  return 0;
};
