import { AlertBanner } from "@moller/design-system";
import { styled } from "styled-components";
import { useFeatureToggle } from "../../feature-toggle/featureToggleProvider";

export const Alert = styled(AlertBanner)`
  margin-bottom: var(--moller-spacing-xl);
`;

export const AlertMessage = () => {
  const { alertMessage } = useFeatureToggle();

  if (alertMessage && alertMessage.message)
    return (
      <Alert
        message={alertMessage.message}
        heading={alertMessage.heading || ""}
        type={alertMessage.type || "info"}
      />
    );
  else return <></>;
};
