"use client";

import { useEffect } from "react";
import { initializeDatadog } from "./initializeDatadog";

// This component only makes sure that Datadog and Google Tag Manager is initialized client side
export const TrackingWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useEffect(() => {
    initializeDatadog();
  }, []);

  return <>{children}</>;
};
