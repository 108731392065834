import { Department, DepartmentGroup } from "@/data/forhandler/departments";

export type DepartmentMenuItem = { name: string; slug: string };

export const getMenuDealers = (
  dealerGroups: DepartmentGroup[],
  independentDealers: Department[],
) => {
  const dealerGroupMenuItems: DepartmentMenuItem[] = dealerGroups
    .filter((d) => !!d.slug)
    .map((d) => {
      return { name: d.name, slug: d.slug || "" };
    });

  const independentDealersMenuItems: DepartmentMenuItem[] = independentDealers
    .filter((d) => !!d.slug)
    .map((d) => {
      return { name: d.name, slug: d?.slug || "" };
    });

  return [...dealerGroupMenuItems, ...independentDealersMenuItems];
};
