"use client";

import { globalPaddingInline } from "@/styles/mixins";
import { Link, Logo, breakpointDesktop } from "@moller/design-system";
import { styled } from "styled-components";

const Nav = styled.nav`
  display: flex;
  align-items: center;
  min-height: calc(55px - calc(var(--moller-spacing-xs) * 2) - 1px);
  padding-block: var(--moller-spacing-xs);
  ${globalPaddingInline()}
  background-color: var(--moller-color-background);

  @media screen and (min-width: ${breakpointDesktop}) {
    min-height: calc(60px - calc(var(--moller-spacing-xs) * 2) - 1px);
    border-bottom: 0.5px solid var(--moller-color-outline);
  }
`;

export const MollerBilHeader = () => {
  return (
    <header>
      <Nav>
        <Link href="https://mollerbil.no" target="_blank">
          <Logo size="xxl" type="wordmark" brand="mollerbil" color="#0000ff" />
        </Link>
      </Nav>
    </header>
  );
};
