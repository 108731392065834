"use client";

import React, { createContext, useContext, useEffect, useState } from "react";
import { calculateTradeInPrice } from "@/components/tradeIn/utils/calculateTradeInPrice";

export interface TradeInContext {
  marketPrice: string;
  setMarketPrice: (marketPrice: string) => void;
  tradeInPrice: number;
  setTradeInPrice: (tradeInPrice: number) => void;
  isTradeInPricingOn: boolean;
  setTradeInPricingOn: (isTradeInPricingOn: boolean) => void;
  isShowingTradeInModal: boolean;
  setIsShowingTradeInModal: (isShowingTradeInModal: boolean) => void;
}

const tradeInContextDefaultVal: TradeInContext = {
  marketPrice: "",
  setMarketPrice: () => undefined,
  tradeInPrice: 0,
  setTradeInPrice: () => undefined,
  isTradeInPricingOn: false,
  setTradeInPricingOn: () => undefined,
  isShowingTradeInModal: false,
  setIsShowingTradeInModal: () => undefined,
};

export const TradeInContext = createContext(tradeInContextDefaultVal);
export const useTradeInContext = () => useContext(TradeInContext);

export const TradeInProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isTradeInPricingOn, setTradeInPricingOn] = useState(
    tradeInContextDefaultVal.isTradeInPricingOn,
  );
  const [isShowingTradeInModal, setIsShowingTradeInModal] = useState(
    tradeInContextDefaultVal.isShowingTradeInModal,
  );
  const [marketPrice, setMarketPrice] = useState(
    tradeInContextDefaultVal.marketPrice,
  );
  const [tradeInPrice, setTradeInPrice] = useState(
    tradeInContextDefaultVal.tradeInPrice,
  );

  useEffect(() => {
    setTradeInPrice(calculateTradeInPrice(marketPrice));
  }, [marketPrice]);

  return (
    <TradeInContext.Provider
      value={{
        marketPrice,
        setMarketPrice,
        tradeInPrice,
        setTradeInPrice,
        isTradeInPricingOn,
        setTradeInPricingOn,
        isShowingTradeInModal,
        setIsShowingTradeInModal,
      }}
    >
      {children}
    </TradeInContext.Provider>
  );
};
