import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { getEnvironment } from "@/environment/environment";

const APPLICATION_ID = "1545de93-ab54-4181-939d-b926c01647f7";
const CLIENT_TOKEN = "pub2141ca1b6d1d937c7b4f8f587366dfc3";
const SERVICE_NAME = "bruktbilsalg-web";
const BUILD_TAG_VERSION = process.env.NEXT_PUBLIC_BUILD_TAG_VERSION;
const DATADOG_SITE = "datadoghq.eu";

export const initializeDatadog = () => {
  const env = getEnvironment();

  // All API calls are proxied to the same origin
  const apiUrl = window.location.origin;

  // not initializing Datadog locally or if server side
  if (env === "local" || typeof window === "undefined") {
    return;
  }

  datadogLogs.init({
    clientToken: CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: SERVICE_NAME,
    env: env,
    version: BUILD_TAG_VERSION,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });

  datadogRum.init({
    applicationId: APPLICATION_ID,
    clientToken: CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: SERVICE_NAME,
    env: env,
    version: BUILD_TAG_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [apiUrl],
    traceSampleRate: 100,
  });

  datadogRum.startSessionReplayRecording();
};

export const getCspReportUri = () => {
  // server side
  const env = getEnvironment();

  const cspReportTags = {
    env: env,
    service: SERVICE_NAME,
    version: BUILD_TAG_VERSION,
  };

  const cspReportTagsString = encodeURIComponent(
    Object.entries(cspReportTags)
      .map(([key, value]) => `${key}:${value}`)
      .join(","),
  );

  // Preparing the URL: https://docs.datadoghq.com/integrations/content_security_policy_logs/?tab=firefox#prepare-a-url-for-the-csp
  return `https://csp-report.browser-intake-${DATADOG_SITE}/api/v2/logs?dd-api-key=${CLIENT_TOKEN}&dd-evp-origin=content-security-policy&ddsource=csp-report&ddtags=${cspReportTagsString}`;
};
