"use client";

import { styled } from "styled-components";
import NextLink from "../nextLink";
import {
  IconButton,
  ActionText,
  breakpointDesktop,
  Subtitle,
} from "@moller/design-system";
import { globalPaddingInline } from "../../styles/mixins";
import { Menu } from "./menu";
import { useState } from "react";
import { useOutsideClick } from "../../utils/hooks/useOutsideClick";
import { MobileMenu } from "./mobileMenu";
import { AlertMessage } from "../alertMessage/alertMessage";
import { DepartmentGroup } from "@/data/forhandler/departments";

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: var(--moller-spacing-s);
  min-height: calc(55px - calc(var(--moller-spacing-xs) * 2) - 1px);
  padding-block: var(--moller-spacing-xs);
  ${globalPaddingInline()}
  background-color: var(--moller-color-background);

  @media screen and (min-width: ${breakpointDesktop}) {
    min-height: calc(60px - calc(var(--moller-spacing-xs) * 2) - 1px);
    border-bottom: 0.5px solid var(--moller-color-outline);
  }
`;

const Logo = styled.div`
  ${Subtitle.selector} {
    font-size: var(--moller-type-medium-subtitle-font-size);
    font-weight: var(--moller-type-large-subtitle-font-weight);
    color: var(--moller-color-on-background);
    text-decoration: none;

    &:visited {
      color: var(--moller-color-on-background);
    }
  }
`;

const Items = styled.div`
  display: none;
  align-items: center;
  padding: 0 var(--moller-spacing-3xl);
  column-gap: var(--moller-spacing-xl);
  a {
    color: var(--moller-color-on-background);
    text-decoration: none;
  }

  @media screen and (min-width: ${breakpointDesktop}) {
    display: flex;
  }
`;

export const Navigation = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const ref = useOutsideClick(() => setMenuOpen(false));

  return (
    <>
      <Nav>
        <Logo>
          <Subtitle $as={NextLink} href="/">
            Bruktbil
          </Subtitle>
        </Logo>
        <Items>
          <ActionText $as="a" href="/">
            Alle biler
          </ActionText>
          <div ref={ref}>
            <IconButton
              icon={isMenuOpen ? "expand_less" : "expand_more"}
              label="Velg forhandler"
              onClick={() => setMenuOpen(!isMenuOpen)}
              showLabel="left"
            />
            {isMenuOpen && <Menu closeMenu={() => setMenuOpen(false)} />}
          </div>
        </Items>
        <MobileMenu />
      </Nav>
      <AlertMessage />
    </>
  );
};
