"use client";

import { Department, DepartmentGroup } from "@/data/forhandler/departments";
import { createContext, useContext } from "react";

export const SanityDealerContext = createContext<{
  dealerGroups: DepartmentGroup[];
  independentDealers: Department[];
}>({
  dealerGroups: [],
  independentDealers: [],
});

export const SanityDealerProvider = ({
  children,
  dealerGroups,
  independentDealers,
}: {
  children: React.ReactNode;
  dealerGroups: DepartmentGroup[];
  independentDealers: Department[];
}) => {
  return (
    <SanityDealerContext.Provider value={{ dealerGroups, independentDealers }}>
      {children}
    </SanityDealerContext.Provider>
  );
};

export const useSanityDealers = () => {
  const context = useContext(SanityDealerContext);
  const dealerGroups = context.dealerGroups;
  const independentDealers = context.independentDealers;

  return { dealerGroups, independentDealers };
};
