"use client";

import { createContext, useContext } from "react";
import { FeatureToggles } from "./featureToggle.types";

export const FeatureToggleContext = createContext<{
  featureToggles: FeatureToggles;
}>({
  featureToggles: {
    feedbackMessageForm: undefined,
    cspReportOnly: undefined,
    cspKillswitch: undefined,
    alertMessage: undefined,
    enableDealersFromSanity: undefined,
  },
});

export const FeatureToggleProvider = ({
  children,
  featureToggles,
}: {
  children: React.ReactNode;
  featureToggles: FeatureToggles;
}) => {
  return (
    <FeatureToggleContext.Provider value={{ featureToggles }}>
      {children}
    </FeatureToggleContext.Provider>
  );
};

export const useFeatureToggle = (): FeatureToggles => {
  const context = useContext(FeatureToggleContext);

  return context.featureToggles;
};
