"use client";

import { styled } from "styled-components";
import { globalPaddingInline } from "../../styles/mixins";
import { MenuSection } from "./menuSection";
import { breakpointDesktop } from "@moller/design-system";
import {
  DepartmentMenuItem,
  getMenuDealers,
} from "./data/getDepartmentsMenuData";
import { sortAlphabetically } from "../../utils/sortAlphabetically";
import { useFeatureToggle } from "@/feature-toggle/featureToggleProvider";
import { useSanityDealers } from "../dealers/dealerProvider";
import {
  departmentGroups,
  standaloneDepartments,
} from "@/data/forhandler/departments.config";

const MenuWrapper = styled.nav`
  padding-bottom: calc(var(--moller-spacing-xl) * 2);

  @media screen and (min-width: ${breakpointDesktop}) {
    position: absolute;
    top: 60px;
    right: 0;
    left: 0;
    z-index: 100;
    display: flex;
    column-gap: var(--moller-spacing-3xl);
    padding-block: var(--moller-spacing-l) var(--moller-spacing-3xl);
    ${globalPaddingInline()}
    background-color: var(--moller-color-background);
    box-shadow: var(--moller-elevation-low);
  }
`;

interface Props {
  closeMenu: () => void;
}

export const Menu = ({ closeMenu }: Props) => {
  const { enableDealersFromSanity } = useFeatureToggle();

  const { dealerGroups, independentDealers } = useSanityDealers();

  const departments = enableDealersFromSanity
    ? getMenuDealers(dealerGroups, independentDealers)
    : getMenuDealers(departmentGroups, standaloneDepartments);
  const sortedDepartments = sortAlphabetically<DepartmentMenuItem[]>(
    departments,
    "name",
  );

  return (
    <MenuWrapper>
      <MenuSection
        departments={sortedDepartments}
        startLetter="A"
        endLetter="E"
        closeMenu={closeMenu}
      />
      <MenuSection
        departments={sortedDepartments}
        startLetter="F"
        endLetter="J"
        closeMenu={closeMenu}
      />
      <MenuSection
        departments={sortedDepartments}
        startLetter="K"
        endLetter="O"
        closeMenu={closeMenu}
      />
      <MenuSection
        departments={sortedDepartments}
        startLetter="P"
        endLetter="T"
        closeMenu={closeMenu}
      />
      <MenuSection
        departments={sortedDepartments}
        startLetter="U"
        endLetter="Å"
        closeMenu={closeMenu}
      />
    </MenuWrapper>
  );
};
