export const setThemeColor = () => {
  const element = document.body;
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === "attributes") {
        const metaThemeElement = document.getElementById("themeColor");
        const bodyColor = getComputedStyle(document.body).backgroundColor;
        metaThemeElement?.setAttribute("content", bodyColor);
      }
    });
  });

  observer.observe(element, {
    attributes: true,
  });
};
