"use client";

import { styled } from "styled-components";
import { globalPaddingInline } from "../../styles/mixins";
import { breakpointDesktop } from "@moller/design-system";
import React, { ReactNode, useEffect, useState } from "react";
import { Footer } from "../footer/footer";
import { Spinner } from "./Spinner";
import { setThemeColor } from "@/components/styled-common/setThemeColor";

const Content = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--moller-spacing-m);
  padding-block: var(--moller-spacing-xs) 82px;
  ${globalPaddingInline()}
  background-color: var(--moller-color-background);

  @media screen and (min-width: ${breakpointDesktop}) {
    padding-block: var(--moller-spacing-l) 82px;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

interface Props {
  children: ReactNode;
}

export const Main: React.FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setThemeColor();
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    );
  }
  return (
    <>
      <Content>{children}</Content>
      <Footer />
    </>
  );
};
