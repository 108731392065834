export type Environment = "local" | "dev" | "prod" | "stage";

const webUrlEnvironmentMap: Record<Environment, string[]> = {
  prod: [
    "https://bruktbilsalg-web.prod.mollercloud.no",
    "https://ny.bruktbil.no",
    "https://www.bruktbil.no",
    "https://bruktbil.mollerbil.no",
  ],
  dev: [
    "https://bruktbilsalg-web.dev.mollercloud.no",
    "https://bruktbil-dev.mollerbil.no",
  ],
  stage: [
    "https://bruktbilsalg-web.stage.mollercloud.no",
    "https://bruktbil-stage.mollerbil.no",
  ],
  local: ["http://localhost:3000", "http://bruktbil.mollerbil.localhost:3000"],
};

export const getEnvironment = (): Environment => {
  if (typeof window === "undefined") {
    return getEnvironmentServerSide();
  }
  return getEnvironmentClientSide();
};

const getEnvironmentServerSide = (): Environment => {
  const nextEnv = process.env.NEXT_ENV;

  switch (nextEnv) {
    case "production":
      return "prod";
    case "development":
      return "dev";
    case "stage":
      return "stage";
    default:
      return "local";
  }
};

const getEnvironmentClientSide = (): Environment => {
  return getEnvFromUrl(window.location.origin);
};

const getEnvFromUrl = (url: string): Environment => {
  if (webUrlEnvironmentMap.prod.includes(url)) {
    return "prod";
  }

  if (webUrlEnvironmentMap.dev.includes(url)) {
    return "dev";
  }

  if (webUrlEnvironmentMap.stage.includes(url)) {
    return "stage";
  }

  if (webUrlEnvironmentMap.local.includes(url)) {
    return "local";
  }

  return "dev";
};

const valuationUrlEnvironmentMap: Record<Environment, string> = {
  prod: "https://verdivurdering.moller.no",
  stage: "https://verdivurdering.stage.mollercloud.no",
  dev: "https://verdivurdering.dev.mollercloud.no",
  local: "http://localhost:3001",
};

export const getValuationUrl = () => {
  const env = getEnvironment();

  switch (env) {
    case "prod":
      return valuationUrlEnvironmentMap.prod;
    case "stage":
      return valuationUrlEnvironmentMap.stage;
    case "dev":
      return valuationUrlEnvironmentMap.dev;
    default:
      return valuationUrlEnvironmentMap.local;
  }
};

const cmsUrlEnvironmentMap: Record<Environment, string> = {
  prod: "https://bruktbilsalg-cms.prod.mollercloud.no",
  dev: "https://bruktbilsalg-cms.dev.mollercloud.no",
  stage: "https://bruktbilsalg-cms.stage.mollercloud.no",
  local: "http://localhost:3333",
};

export const getBruktbilsalgCmsUrl = () => {
  const env = getEnvironment();
  switch (env) {
    case "prod":
      return cmsUrlEnvironmentMap.prod;
    case "dev":
      return cmsUrlEnvironmentMap.dev;
    case "stage":
      return cmsUrlEnvironmentMap.stage;
    default:
      return cmsUrlEnvironmentMap.local;
  }
};
